import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App/App';
import { oauth2 as SMART } from "fhirclient";

const root = ReactDOM.createRoot(document.getElementById('root'));

// SMART on FHIR
SMART.init({
  iss: "https://fhir.epic.com/interconnect-fhir-oauth/api/FHIR/R4/",
  // redirectUri: "https://agreeable-tree-0e54b5310.2.azurestaticapps.net",
  redirectUri: "https://app.lexifyhealth.com",
  clientId: "41cb7223-6111-4cc8-9d14-1ad7e53042d6",
  scope: "openid Patient.read Patient.search ",
  completeInTarget: true
})
  .then(client => {
      return Promise.all([
          // client.patient.read()
      ]);
  })
  .then(
      ([patient]) => {
          root.render(<React.StrictMode><App patient={patient} /></React.StrictMode>);
      },
      error => {
          console.error(error);
          root.render(
              <>
                  <br />
                  <pre>{error.stack}</pre>
              </>,
              root
          );
      }
  );

// No SMART on FHIR
// root.render(<React.StrictMode><App  /></React.StrictMode>);